import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { whoWeArePageHolder } from "./who-we-are.module.scss"
import Person from "../components/components/Person"
import Seo from "../components/components/Seo"

export default function WhoWeArePage() {
  const data = useStaticQuery(graphql`
    query WhoAreWeQuery {
      allWpPerson(sort: { fields: menuOrder, order: ASC }) {
        nodes {
          title
          menuOrder
          id
          personInfo {
            about
            emailAddress
            role
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const people = data.allWpPerson.nodes

  return (
    <article className={whoWeArePageHolder}>
      <Seo title="Who We Are" />
      <h1 className="gambarinoHeader">Who We Are</h1>
      <ul>
        {people.map(person => (
          <Person key={person.id} person={person} />
        ))}
      </ul>
    </article>
  )
}
