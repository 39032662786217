import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { personHolder } from "./Person.module.scss";

export default function Person({ person }) {
   const image = getImage(person?.featuredImage?.node?.localFile);

   return (
      <li className={personHolder}>
         {image && <GatsbyImage image={image} alt={person.title} />}

         <div className="personInfo">
            <h3>{person.title}</h3>
            {person.personInfo.role && (
               <div className="role">{person.personInfo.role}</div>
            )}
            {person.personInfo.about && (
               <div
                  className="about"
                  dangerouslySetInnerHTML={{ __html: person.personInfo.about }}
               />
            )}
            {person.personInfo.emailAddress && (
               <div className="email">
                  <a
                     href={`mailto:${person.personInfo.emailAddress}`}
                     title={`${person.title}'s email`}
                  >
                     {person.personInfo.emailAddress}
                  </a>
               </div>
            )}
         </div>
      </li>
   );
}
